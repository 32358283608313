import React from "react"
import "../styles/basicBanner.css"
import Banner from "../images/banner.js"

export default function BasicBanner(props) {

    return (
        <div className="banner">
            <div className="bannerContainer">
                <div className="bannerText">{props.text}</div>
                <div className="bannerImage">
                    <Banner></Banner>
                </div>
            </div>
        </div>
    )
}
import * as React from "react"

export default function Banner() {
    return (

        <svg width="790" height="200" viewBox="0 0 2223 583" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M711.687 582.871C711.272 582.88 710.866 582.793 710.545 582.628L550.32 499.186L2.14355 579.291C1.68949 579.356 1.21314 579.304 0.819288 579.149C0.42544 578.993 0.146357 578.745 0.0434361 578.461C-0.0594852 578.176 0.0221854 577.877 0.270481 577.63C0.518777 577.383 0.913359 577.208 1.36742 577.143L550.366 496.945C550.623 496.907 550.892 496.907 551.149 496.946C551.407 496.984 551.647 497.06 551.849 497.167L712.782 580.945C712.956 581.035 713.099 581.146 713.204 581.27C713.309 581.395 713.373 581.532 713.393 581.672C713.414 581.813 713.389 581.954 713.322 582.089C713.254 582.223 713.144 582.348 712.999 582.456C712.845 582.583 712.647 582.687 712.419 582.759C712.192 582.831 711.941 582.869 711.687 582.871Z" fill="#6D88A4" />
            <path d="M1051.81 582.871C1051.41 582.871 1051.02 582.782 1050.71 582.619C1050.4 582.457 1050.2 582.231 1050.13 581.983C1050.06 581.734 1050.13 581.478 1050.33 581.259C1050.53 581.041 1050.85 580.874 1051.23 580.788L1294.34 525.909V451.655L978.776 416.031L436.044 479.581V515.025C436.044 515.31 435.864 515.584 435.543 515.785C435.222 515.986 434.786 516.1 434.332 516.1C433.878 516.1 433.443 515.986 433.122 515.785C432.8 515.584 432.62 515.31 432.62 515.025V478.686C432.619 478.436 432.758 478.193 433.012 478.001C433.267 477.809 433.621 477.679 434.013 477.633L978.445 413.905C978.653 413.879 978.866 413.879 979.073 413.905L1296.37 449.707C1296.77 449.754 1297.12 449.885 1297.38 450.079C1297.64 450.272 1297.78 450.515 1297.78 450.767V526.669C1297.78 526.89 1297.67 527.106 1297.46 527.287C1297.26 527.468 1296.97 527.604 1296.64 527.678L1052.38 582.814C1052.2 582.853 1052.01 582.873 1051.81 582.871V582.871Z" fill="#6D88A4" />
            <path d="M748.208 547.785C748.018 547.784 747.83 547.765 747.649 547.728L433.773 479.703C433.56 479.656 433.364 479.583 433.196 479.488C433.028 479.394 432.891 479.279 432.794 479.152C432.696 479.024 432.64 478.886 432.628 478.744C432.615 478.603 432.648 478.461 432.723 478.328C432.797 478.194 432.914 478.071 433.064 477.966C433.215 477.861 433.397 477.775 433.601 477.714C433.804 477.653 434.025 477.617 434.25 477.609C434.476 477.602 434.701 477.622 434.914 477.669L748.288 545.587L1295.61 449.729C1296.04 449.662 1296.5 449.704 1296.88 449.845C1297.27 449.986 1297.55 450.216 1297.67 450.485C1297.78 450.755 1297.73 451.042 1297.52 451.287C1297.3 451.532 1296.95 451.716 1296.52 451.798L748.665 547.749C748.515 547.772 748.362 547.784 748.208 547.785V547.785Z" fill="#6D88A4" />
            <path d="M289.379 537.273C288.925 537.273 288.49 537.16 288.169 536.959C287.848 536.757 287.668 536.484 287.668 536.199V503.748C287.668 503.463 287.848 503.19 288.169 502.988C288.49 502.787 288.925 502.674 289.379 502.674C289.833 502.674 290.268 502.787 290.589 502.988C290.91 503.19 291.09 503.463 291.09 503.748V536.199C291.09 536.484 290.91 536.757 290.589 536.959C290.268 537.16 289.833 537.273 289.379 537.273Z" fill="#6D88A4" />
            <path d="M530.209 468.54C529.755 468.54 529.32 468.427 528.999 468.225C528.677 468.024 528.497 467.75 528.497 467.466V434.291C528.499 434.037 528.643 433.792 528.904 433.598C529.165 433.405 529.526 433.275 529.924 433.231L864.345 397.428C864.799 397.381 865.264 397.449 865.639 397.616C866.013 397.784 866.266 398.039 866.342 398.323C866.418 398.608 866.31 398.9 866.042 399.135C865.775 399.37 865.369 399.529 864.915 399.577L531.921 435.2V467.466C531.921 467.607 531.877 467.746 531.791 467.877C531.705 468.007 531.579 468.125 531.42 468.225C531.261 468.325 531.072 468.404 530.864 468.458C530.657 468.512 530.434 468.54 530.209 468.54V468.54Z" fill="#6D88A4" />
            <path d="M651.889 454.297C651.742 454.296 651.596 454.282 651.455 454.254L529.763 433.932C529.545 433.896 529.342 433.832 529.163 433.746C528.985 433.66 528.835 433.553 528.723 433.431C528.611 433.308 528.538 433.173 528.509 433.033C528.479 432.893 528.494 432.751 528.553 432.615C528.611 432.478 528.712 432.351 528.849 432.239C528.986 432.127 529.157 432.033 529.352 431.962C529.547 431.892 529.763 431.846 529.986 431.828C530.209 431.81 530.436 431.819 530.653 431.856L652.334 452.185C652.551 452.22 652.755 452.283 652.933 452.368C653.112 452.453 653.261 452.56 653.374 452.682C653.486 452.804 653.559 452.938 653.588 453.078C653.618 453.217 653.603 453.359 653.544 453.495C653.448 453.725 653.234 453.928 652.934 454.073C652.635 454.218 652.267 454.297 651.889 454.297Z" fill="#6D88A4" />
            <path d="M1431.89 443.241H1431.58L866.343 377.587L867.484 428.548C867.487 428.689 867.446 428.829 867.363 428.96C867.279 429.091 867.156 429.211 866.999 429.312C866.842 429.413 866.655 429.493 866.448 429.549C866.242 429.605 866.02 429.635 865.795 429.636V429.636C865.345 429.636 864.913 429.525 864.592 429.327C864.272 429.129 864.089 428.859 864.083 428.577L862.942 376.305C862.936 376.25 862.936 376.195 862.942 376.14C862.936 376.09 862.936 376.04 862.942 375.99C863.029 375.792 863.203 375.613 863.444 375.474V375.474C863.535 375.423 863.634 375.377 863.74 375.338V375.338L1036.09 317.338C1036.4 317.231 1036.77 317.188 1037.13 317.216L1088.49 321.513L1385.24 346.574C1385.51 346.597 1385.76 346.659 1385.97 346.753L1551.47 416.926C1551.73 417.04 1551.94 417.197 1552.07 417.38C1552.21 417.564 1552.25 417.766 1552.21 417.965C1552.17 418.162 1552.04 418.348 1551.84 418.503C1551.64 418.657 1551.37 418.773 1551.07 418.838L1432.37 443.184C1432.22 443.218 1432.05 443.237 1431.89 443.241ZM869.767 375.789L1431.78 441.093L1546.73 417.464L1384.52 348.68L1088.11 323.661L1037.3 319.407L869.767 375.789Z" fill="#6D88A4" />
            <path d="M1431.89 530.6C1431.44 530.6 1431 530.486 1430.68 530.285C1430.36 530.084 1430.18 529.81 1430.18 529.526V442.332L1383.46 348.006C1383.38 347.869 1383.34 347.723 1383.36 347.577C1383.37 347.432 1383.43 347.289 1383.53 347.157C1383.63 347.026 1383.77 346.909 1383.95 346.814C1384.13 346.719 1384.33 346.648 1384.56 346.604C1384.78 346.561 1385.01 346.547 1385.25 346.562C1385.48 346.577 1385.7 346.622 1385.9 346.694C1386.11 346.765 1386.28 346.862 1386.43 346.978C1386.57 347.094 1386.67 347.227 1386.73 347.369L1433.52 441.888C1433.58 441.989 1433.6 442.096 1433.6 442.203V529.561C1433.59 529.84 1433.4 530.104 1433.08 530.298C1432.76 530.492 1432.33 530.6 1431.89 530.6V530.6Z" fill="#6D88A4" />
            <path d="M1550.59 580.007C1550.14 580.007 1549.7 579.894 1549.38 579.693C1549.06 579.491 1548.88 579.218 1548.88 578.933V417.821C1548.88 417.537 1549.06 417.263 1549.38 417.062C1549.7 416.86 1550.14 416.747 1550.59 416.747C1551.05 416.747 1551.48 416.86 1551.8 417.062C1552.12 417.263 1552.3 417.537 1552.3 417.821V578.933C1552.3 579.218 1552.12 579.491 1551.8 579.693C1551.48 579.894 1551.05 580.007 1550.59 580.007Z" fill="#6D88A4" />
            <path d="M1692.12 380.945H1691.91L1408.28 358.64C1407.83 358.604 1407.42 358.458 1407.14 358.234C1406.86 358.01 1406.74 357.726 1406.79 357.444C1406.85 357.161 1407.09 356.905 1407.45 356.731C1407.81 356.557 1408.26 356.478 1408.71 356.513L1691.9 378.796L1926.06 318.347L1701.08 263.576L1509.9 284.278L1237.7 336.213C1237.27 336.287 1236.81 336.253 1236.42 336.118C1236.03 335.982 1235.74 335.757 1235.61 335.489C1235.48 335.221 1235.52 334.932 1235.73 334.683C1235.93 334.434 1236.28 334.246 1236.71 334.158L1509 282.208L1509.22 282.172L1700.97 261.407C1701.27 261.375 1701.58 261.395 1701.87 261.464L1931.29 317.316C1931.61 317.395 1931.88 317.533 1932.07 317.711C1932.27 317.889 1932.37 318.1 1932.37 318.315C1932.37 318.531 1932.27 318.741 1932.07 318.919C1931.88 319.097 1931.61 319.235 1931.29 319.314L1692.74 380.895C1692.54 380.934 1692.33 380.951 1692.12 380.945Z" fill="#6D88A4" />
            <path d="M1692.12 443.241C1691.67 443.241 1691.23 443.128 1690.91 442.927C1690.59 442.725 1690.41 442.452 1690.41 442.167V380.372L1508.4 284.027C1508.05 283.843 1507.83 283.579 1507.79 283.295C1507.76 283.01 1507.9 282.727 1508.19 282.509C1508.49 282.291 1508.91 282.154 1509.36 282.13C1509.81 282.106 1510.26 282.196 1510.61 282.38L1693.23 379.047C1693.42 379.15 1693.57 379.276 1693.67 379.418C1693.78 379.56 1693.83 379.714 1693.84 379.871V442.167C1693.84 442.308 1693.79 442.448 1693.71 442.578C1693.62 442.708 1693.49 442.827 1693.33 442.927C1693.18 443.026 1692.99 443.105 1692.78 443.159C1692.57 443.213 1692.35 443.241 1692.12 443.241Z" fill="#6D88A4" />
            <path d="M1675 580.007C1674.55 580.007 1674.11 579.894 1673.79 579.692C1673.47 579.491 1673.29 579.218 1673.29 578.933V457.92C1673.29 457.764 1673.34 457.611 1673.45 457.469C1673.55 457.328 1673.7 457.203 1673.9 457.104C1674.08 457.002 1674.31 456.927 1674.55 456.885C1674.79 456.843 1675.04 456.835 1675.29 456.86L2206.02 511.996C2206.48 512.043 2206.88 512.201 2207.15 512.435C2207.42 512.669 2207.53 512.961 2207.46 513.246C2207.38 513.531 2207.13 513.785 2206.76 513.954C2206.38 514.123 2205.92 514.191 2205.46 514.145L1676.71 459.188V578.933C1676.71 579.218 1676.53 579.491 1676.21 579.692C1675.89 579.894 1675.46 580.007 1675 580.007Z" fill="#6D88A4" />
            <path d="M1675 458.995C1674.7 458.994 1674.4 458.944 1674.14 458.847C1673.88 458.751 1673.66 458.613 1673.51 458.446C1673.36 458.28 1673.29 458.092 1673.29 457.902C1673.3 457.711 1673.38 457.525 1673.54 457.362L1726.04 402.942C1726.22 402.765 1726.47 402.624 1726.76 402.533C1727.06 402.443 1727.39 402.409 1727.72 402.434L2220.79 441.817C2221.25 441.853 2221.66 442 2221.94 442.227C2222.22 442.454 2222.35 442.742 2222.29 443.027C2222.23 443.312 2222 443.57 2221.63 443.746C2221.27 443.922 2220.81 444.001 2220.36 443.965L1728.43 404.668L1676.5 458.493C1676.34 458.651 1676.12 458.78 1675.86 458.868C1675.59 458.956 1675.3 459 1675 458.995Z" fill="#6D88A4" />
            <path d="M1794.63 409.937C1794.17 409.937 1793.74 409.824 1793.42 409.623C1793.1 409.421 1792.92 409.148 1792.92 408.863V353.412C1792.92 353.127 1793.1 352.854 1793.42 352.652C1793.74 352.451 1794.17 352.338 1794.63 352.338C1795.08 352.338 1795.52 352.451 1795.84 352.652C1796.16 352.854 1796.34 353.127 1796.34 353.412V408.87C1796.34 409.154 1796.15 409.425 1795.83 409.625C1795.51 409.825 1795.08 409.937 1794.63 409.937V409.937Z" fill="#6D88A4" />
            <path d="M1841.64 363.76H1841.39L1794.38 359.463C1794.15 359.443 1793.94 359.395 1793.74 359.323C1793.54 359.25 1793.37 359.154 1793.24 359.04C1793.1 358.926 1793 358.796 1792.95 358.658C1792.89 358.52 1792.88 358.376 1792.91 358.235C1792.94 358.094 1793.02 357.958 1793.13 357.836C1793.25 357.713 1793.4 357.606 1793.58 357.521C1793.77 357.435 1793.97 357.373 1794.19 357.338C1794.41 357.303 1794.64 357.295 1794.87 357.315L1841.41 361.611L1992.43 321.204L1824.41 277.89L1786.99 284.278C1786.77 284.314 1786.55 284.324 1786.32 284.305C1786.1 284.287 1785.89 284.241 1785.69 284.171C1785.5 284.1 1785.33 284.007 1785.19 283.895C1785.05 283.783 1784.95 283.655 1784.89 283.519C1784.83 283.382 1784.82 283.24 1784.85 283.1C1784.88 282.96 1784.95 282.825 1785.06 282.703C1785.17 282.58 1785.32 282.473 1785.5 282.387C1785.68 282.301 1785.88 282.238 1786.1 282.201L1824.08 275.757C1824.45 275.693 1824.85 275.708 1825.22 275.8L1997.56 320.195C1997.88 320.276 1998.14 320.414 1998.33 320.59C1998.52 320.767 1998.62 320.974 1998.62 321.187C1998.62 321.399 1998.52 321.606 1998.33 321.783C1998.14 321.959 1997.88 322.097 1997.56 322.178L1842.34 363.709C1842.11 363.756 1841.88 363.773 1841.64 363.76V363.76Z" fill="#6D88A4" />
            <path d="M1962.63 408.154C1962.17 408.154 1961.74 408.041 1961.42 407.84C1961.09 407.638 1960.91 407.365 1960.91 407.08L1961.29 330.212C1961.29 329.927 1961.47 329.654 1961.79 329.453C1962.11 329.251 1962.55 329.138 1963 329.138V329.138C1963.46 329.14 1963.89 329.255 1964.21 329.457C1964.53 329.66 1964.71 329.934 1964.71 330.219L1964.34 407.088C1964.33 407.371 1964.15 407.642 1963.83 407.842C1963.51 408.042 1963.08 408.154 1962.63 408.154V408.154Z" fill="#6D88A4" />
            <path d="M1826.8 412.451C1826.58 412.45 1826.36 412.421 1826.15 412.366C1825.94 412.312 1825.75 412.232 1825.6 412.131C1825.44 412.031 1825.31 411.912 1825.23 411.781C1825.14 411.651 1825.1 411.511 1825.1 411.37L1825.66 361.368C1825.67 361.084 1825.85 360.813 1826.17 360.613C1826.49 360.413 1826.92 360.301 1827.37 360.301V360.301C1827.83 360.306 1828.26 360.424 1828.58 360.627C1828.9 360.831 1829.07 361.104 1829.07 361.389L1828.51 411.384C1828.51 411.668 1828.33 411.939 1828.01 412.139C1827.69 412.339 1827.26 412.451 1826.8 412.451Z" fill="#6D88A4" />
            <path d="M2014.1 427.488C2013.73 427.49 2013.38 427.418 2013.08 427.283C2012.78 427.147 2012.56 426.955 2012.45 426.735C2012.34 426.515 2012.35 426.279 2012.47 426.061C2012.59 425.843 2012.82 425.656 2013.12 425.526L2081.73 395.917L1999.62 394.549L1931.57 420.879C1931.18 421.029 1930.72 421.076 1930.27 421.01C1929.83 420.944 1929.45 420.771 1929.21 420.528C1928.97 420.285 1928.89 419.992 1929 419.714C1929.1 419.436 1929.38 419.196 1929.77 419.046L1998.25 392.552C1998.53 392.44 1998.86 392.385 1999.2 392.394L2087.08 393.826C2087.44 393.831 2087.79 393.909 2088.08 394.047C2088.37 394.185 2088.58 394.377 2088.68 394.595C2088.79 394.814 2088.78 395.047 2088.66 395.261C2088.53 395.476 2088.31 395.66 2088.01 395.788L2015.07 427.294C2014.79 427.419 2014.45 427.487 2014.1 427.488V427.488Z" fill="#6D88A4" />
            <path d="M2175.85 440.377C2175.6 440.379 2175.35 440.344 2175.13 440.277L2086.32 414.499C2086.02 414.414 2085.77 414.277 2085.59 414.104C2085.42 413.931 2085.32 413.73 2085.32 413.525V394.908C2085.32 394.623 2085.5 394.35 2085.83 394.149C2086.15 393.947 2086.58 393.834 2087.04 393.834C2087.49 393.834 2087.93 393.947 2088.25 394.149C2088.57 394.35 2088.75 394.623 2088.75 394.908V412.809L2176.57 438.308C2176.92 438.411 2177.21 438.587 2177.38 438.806C2177.55 439.025 2177.6 439.274 2177.52 439.513C2177.43 439.752 2177.22 439.965 2176.92 440.118C2176.61 440.272 2176.24 440.355 2175.85 440.356V440.377Z" fill="#6D88A4" />
            <path d="M1483.25 580.007C1482.8 580.007 1482.36 579.894 1482.04 579.693C1481.72 579.491 1481.54 579.218 1481.54 578.933V536.858C1464.84 534.846 1304.35 515.483 1295.75 514.474C1295.53 514.447 1295.32 514.394 1295.13 514.316C1294.94 514.238 1294.78 514.138 1294.65 514.021C1294.53 513.904 1294.44 513.772 1294.39 513.634C1294.35 513.495 1294.35 513.352 1294.39 513.214C1294.43 513.075 1294.52 512.943 1294.64 512.825C1294.76 512.707 1294.92 512.606 1295.11 512.526C1295.29 512.447 1295.5 512.392 1295.72 512.364C1295.94 512.335 1296.17 512.335 1296.39 512.362C1305.42 513.421 1481.79 534.702 1483.57 534.917C1483.96 534.963 1484.32 535.093 1484.57 535.285C1484.83 535.477 1484.97 535.72 1484.96 535.97V578.933C1484.96 579.218 1484.78 579.491 1484.46 579.693C1484.14 579.894 1483.71 580.007 1483.25 580.007Z" fill="#6D88A4" />
            <path d="M1313.19 579.291C1312.79 579.289 1312.41 579.201 1312.11 579.042C1311.8 578.882 1311.6 578.662 1311.52 578.418C1311.45 578.174 1311.51 577.922 1311.7 577.703C1311.89 577.485 1312.19 577.315 1312.56 577.222L1482.62 534.975C1483.04 534.879 1483.51 534.889 1483.91 535.004C1484.32 535.119 1484.64 535.329 1484.8 535.59C1484.97 535.85 1484.96 536.141 1484.79 536.399C1484.62 536.657 1484.29 536.863 1483.88 536.973L1313.82 579.22C1313.62 579.268 1313.4 579.292 1313.19 579.291Z" fill="#6D88A4" />
            <path d="M748.207 580.007C747.753 580.007 747.318 579.894 746.997 579.693C746.676 579.491 746.496 579.218 746.496 578.933V546.711C746.496 546.426 746.676 546.153 746.997 545.951C747.318 545.75 747.753 545.637 748.207 545.637C748.661 545.637 749.096 545.75 749.417 545.951C749.738 546.153 749.918 546.426 749.918 546.711V578.933C749.918 579.218 749.738 579.491 749.417 579.693C749.096 579.894 748.661 580.007 748.207 580.007Z" fill="#6D88A4" />
            <path d="M377.197 524.449C376.986 524.446 376.778 524.422 376.58 524.377L247.72 493.293C247.392 493.214 247.111 493.073 246.915 492.889C246.719 492.706 246.618 492.489 246.625 492.269C246.633 492.048 246.749 491.834 246.957 491.656C247.165 491.478 247.455 491.345 247.788 491.274L433.831 451.891C434.232 451.795 434.675 451.795 435.075 451.891L500.133 470.057C500.343 470.114 500.532 470.196 500.689 470.3C500.847 470.403 500.969 470.525 501.05 470.658C501.131 470.792 501.169 470.934 501.16 471.077C501.152 471.22 501.097 471.36 501.001 471.49C500.908 471.62 500.776 471.737 500.611 471.834C500.446 471.932 500.252 472.009 500.04 472.059C499.828 472.11 499.602 472.133 499.375 472.129C499.148 472.125 498.925 472.092 498.718 472.034L434.276 454.039L253.335 492.355L377.836 522.372C378.209 522.462 378.52 522.632 378.716 522.851C378.911 523.07 378.978 523.325 378.904 523.571C378.831 523.818 378.622 524.041 378.315 524.201C378.007 524.361 377.619 524.449 377.219 524.449H377.197Z" fill="#6D88A4" />
            <path d="M2059.24 431.068C2059 431.07 2058.75 431.038 2058.53 430.974C2058.3 430.909 2058.1 430.814 2057.94 430.696C2057.8 430.59 2057.68 430.466 2057.61 430.332C2057.54 430.198 2057.52 430.057 2057.53 429.917C2057.55 429.776 2057.61 429.639 2057.71 429.514C2057.81 429.388 2057.95 429.277 2058.13 429.185L2085.92 414.148C2086.09 414.054 2086.29 413.982 2086.5 413.936C2086.72 413.89 2086.94 413.871 2087.17 413.88C2087.39 413.889 2087.62 413.926 2087.82 413.988C2088.02 414.051 2088.2 414.138 2088.35 414.245C2088.5 414.352 2088.62 414.476 2088.69 414.61C2088.77 414.745 2088.8 414.887 2088.78 415.029C2088.77 415.171 2088.71 415.31 2088.61 415.438C2088.51 415.565 2088.37 415.68 2088.2 415.774L2060.41 430.811C2060.08 430.982 2059.67 431.074 2059.24 431.068V431.068Z" fill="#6D88A4" />
            <path d="M1891.86 293.586C1891.41 293.586 1890.97 293.473 1890.65 293.272C1890.33 293.07 1890.15 292.797 1890.15 292.512V171.707L1826.6 73.794L1742.91 173.196L1743.03 272.191C1743.03 272.476 1742.85 272.749 1742.53 272.95C1742.2 273.152 1741.77 273.265 1741.32 273.265C1740.86 273.265 1740.43 273.152 1740.1 272.95C1739.78 272.749 1739.6 272.476 1739.6 272.191L1739.49 172.931C1739.49 172.757 1739.56 172.586 1739.68 172.43L1825.29 70.7508C1825.44 70.5692 1825.67 70.4188 1825.95 70.3172C1826.24 70.2157 1826.56 70.1674 1826.89 70.1779C1827.22 70.1885 1827.53 70.2575 1827.8 70.3767C1828.06 70.496 1828.27 70.6606 1828.39 70.851L1893.45 171.098C1893.53 171.226 1893.57 171.362 1893.57 171.499V292.512C1893.57 292.797 1893.39 293.07 1893.07 293.272C1892.75 293.473 1892.32 293.586 1891.86 293.586Z" fill="#6D88A4" />
            <path d="M2116.71 423.192C2116.26 423.192 2115.82 423.079 2115.5 422.877C2115.18 422.676 2115 422.403 2115 422.118V130.899L2029.87 3.69952L1932.38 130.928V301.821C1932.38 302.106 1932.2 302.379 1931.88 302.58C1931.56 302.782 1931.12 302.895 1930.67 302.895C1930.22 302.895 1929.78 302.782 1929.46 302.58C1929.14 302.379 1928.96 302.106 1928.96 301.821V130.684C1928.96 130.523 1929.02 130.364 1929.13 130.219L2028.43 0.613336C2028.57 0.427883 2028.8 0.272295 2029.08 0.165156C2029.36 0.0580176 2029.68 0.00385665 2030.01 0.00914208C2030.34 0.0144275 2030.66 0.078936 2030.93 0.194963C2031.2 0.31099 2031.42 0.47363 2031.54 0.66346L2118.29 130.269C2118.38 130.4 2118.43 130.541 2118.42 130.684V422.118C2118.42 422.403 2118.24 422.676 2117.92 422.877C2117.6 423.079 2117.17 423.192 2116.71 423.192Z" fill="#6D88A4" />
            <path d="M1976.21 72.3259H1826.8C1826.35 72.3259 1825.91 72.2127 1825.59 72.0113C1825.27 71.8099 1825.09 71.5367 1825.09 71.2518C1825.09 70.9669 1825.27 70.6938 1825.59 70.4923C1825.91 70.2909 1826.35 70.1777 1826.8 70.1777H1976.21C1976.66 70.1777 1977.1 70.2909 1977.42 70.4923C1977.74 70.6938 1977.92 70.9669 1977.92 71.2518C1977.92 71.5367 1977.74 71.8099 1977.42 72.0113C1977.1 72.2127 1976.66 72.3259 1976.21 72.3259Z" fill="#6D88A4" />
            <path d="M1930.67 172.573H1891.86C1891.41 172.573 1890.97 172.46 1890.65 172.259C1890.33 172.057 1890.15 171.784 1890.15 171.499C1890.15 171.214 1890.33 170.941 1890.65 170.74C1890.97 170.538 1891.41 170.425 1891.86 170.425H1930.67C1931.12 170.425 1931.56 170.538 1931.88 170.74C1932.2 170.941 1932.38 171.214 1932.38 171.499C1932.38 171.784 1932.2 172.057 1931.88 172.259C1931.56 172.46 1931.12 172.573 1930.67 172.573V172.573Z" fill="#6D88A4" />
            <path d="M2190.9 11.4615H2190.74L2029.81 2.15283C2029.58 2.13966 2029.36 2.09884 2029.16 2.0327C2028.97 1.96656 2028.79 1.8764 2028.64 1.76735C2028.5 1.6583 2028.39 1.53251 2028.32 1.39716C2028.26 1.26181 2028.23 1.11955 2028.26 0.9785C2028.28 0.83745 2028.34 0.700374 2028.45 0.575099C2028.55 0.449823 2028.7 0.338802 2028.87 0.248373C2029.04 0.157944 2029.24 0.0898795 2029.46 0.0480645C2029.68 0.00624956 2029.9 -0.00849654 2030.13 0.00466815L2191.06 9.31336C2191.51 9.32665 2191.94 9.45256 2192.25 9.66339C2192.55 9.87422 2192.71 10.1527 2192.69 10.4376C2192.67 10.7224 2192.47 10.9903 2192.13 11.1824C2191.8 11.3744 2191.35 11.4748 2190.9 11.4615Z" fill="#6D88A4" />
            <path d="M2192.04 131.758H2116.71C2116.26 131.758 2115.82 131.645 2115.5 131.444C2115.18 131.242 2115 130.969 2115 130.684C2115 130.399 2115.18 130.126 2115.5 129.925C2115.82 129.723 2116.26 129.61 2116.71 129.61H2192.04C2192.49 129.61 2192.93 129.723 2193.25 129.925C2193.57 130.126 2193.75 130.399 2193.75 130.684C2193.75 130.969 2193.57 131.242 2193.25 131.444C2192.93 131.645 2192.49 131.758 2192.04 131.758Z" fill="#6D88A4" />
        </svg>
    )
}